import { useState, useEffect } from 'react';
import { Box, Button, Flex, VStack, Text, HStack, Spacer, useBreakpointValue } from '@chakra-ui/react'

import { SlSupport } from "react-icons/sl";

import { IoPersonCircleOutline } from "react-icons/io5";
import { IoArrowBack } from "react-icons/io5";

import { TbLogout2 } from "react-icons/tb";

import SidebarSmall from './SidebarSmall'

import { useLocation } from 'react-router-dom';

import { MdOutlineKeyboardArrowRight } from "react-icons/md";

import { CiBookmarkPlus } from "react-icons/ci";

import { IoLanguageOutline } from "react-icons/io5";

import { GiGearStickPattern } from "react-icons/gi";

import { useData } from '../App';
import { useNavigate } from 'react-router-dom';


import useLogout from '../pages/AuthPages/useLogout';

const SettingsSideBar = () => {

    const isSmallScreen = useBreakpointValue({ base: true, sm: true, md: false });
  const isBaseScreen = useBreakpointValue({ base: true, sm: false, md: false });

  const isLargeScreen = useBreakpointValue({ base: false, sm: false, md: false, lg: true });

  const [isVisible, setIsVisible] = useState(false);

  const { pathname } = useLocation()

  const navigate = useNavigate()


  const { handleLogout } = useLogout();


  
 

  const { 
    
    // showEditProfile, setShowEditProfile,
    isSettingsSelected, setSettingsSelected,
   // isLogoutClicked, setLogOutClicked, 
   setHomeSelected
} = useData();



  const [isSubscriptionClicked, setIsSubscriptionClicked] = useState(false);
  const [isLanguageClicked, setIsLanguageClicked] = useState(false);
  const [isAdvancedClicked, setIsAdvancedClicked] = useState(false);

  const [isDeleteProfileClicked, setDeleteProfileClicked] = useState(false);

  const [title, setTitle] = useState("Settings");


  const closeSettings = () => {

    setSettingsSelected(false);

  };


  const goBack = () => {

    if (isSubscriptionClicked) {
      setIsSubscriptionClicked(false);
      setTitle("Settings");

    } else if (isLanguageClicked) {
      setIsLanguageClicked(false);
      setTitle("Settings");

    } else if (isAdvancedClicked) {
      setIsAdvancedClicked(false);
      setTitle("Settings");

    } else if (isDeleteProfileClicked) {
      setDeleteProfileClicked(false);
      setTitle("Settings");

    } else {
      setSettingsSelected(false);
      setTitle("Settings");
    }




  };

  const goToAdvanced = () => {
    setIsAdvancedClicked(true);
    setIsLanguageClicked(false);
    setIsSubscriptionClicked(false);
    setDeleteProfileClicked(false);
    setTitle("Advanced");
  };

  const goToLanguage = () => {
    setIsAdvancedClicked(false);
    setIsLanguageClicked(true);
    setIsSubscriptionClicked(false);
    setTitle("Language");
  };

  const goToSubscriptions = () => {
    setIsAdvancedClicked(false);
    setIsLanguageClicked(false);
    setIsSubscriptionClicked(true);
    setTitle("Subscriptions");
  };

  const goDeleteAccount = () => {
    setTitle("Delete Account");


    setIsLanguageClicked(false);
    setIsSubscriptionClicked(false);

    setDeleteProfileClicked(true);

  }

  const includedTalkCirclePaths = [


    '/talkcircles',
    '/talkcircles/session='
  ];


  const goToEditProfile = () => {


      setSettingsSelected(false);
  //  setShowEditProfile(true);

  // GO TO EDIT PROFILE ON YENZEK

  window.open('https://www.yenzek.com/prayersend/profile', '_blank');


  };

  const openLogoutPopup = () => {

   // setSettingsSelected(false);
  //  setLogOutClicked(true);

  };

  const goToHelp = () => {
    setSettingsSelected(false);
    // navigate('/help');
    window.open('https://www.yenzek.com/help', '_blank');

  }

  const logOutUser = () => {

    setSettingsSelected(false);
      
    handleLogout();
    navigate('/');
    setHomeSelected(false);

};


  

  const TalkCirclePaths = includedTalkCirclePaths.some(path => pathname.includes(path));

  useEffect(() => {
    setIsVisible(true);

  }, []); // 
  return (
    <Box
      position="fixed"
      top="0"
      left={isVisible ? "0" : "-240px"} // Position based on visibility
      height="100%"
      bg="white"
      borderRight="1px solid #e4e4e4"
      transition="left 0.6s ease" // Slower transition
      zIndex="1"
      minW="470px"
      pb="8px"
     // mt={TalkCirclePaths || isBaseScreen ? "5rem" : null}
      mt="5rem"
      style={{ borderRadius: '20px' }}>




      <HStack>

        {!isBaseScreen && (
          
          <SidebarSmall />
        )}


        <VStack paddingInlineStart="6px" paddingInlineEnd="8px" pl={isBaseScreen ? "1rem" : "5rem"}>


          <Flex direction="column">

            <HStack pt="1rem" pb="1rem">


              <IoArrowBack
                onClick={goBack}
                fontSize='24px'
                _hover={{
                  bg: 'rgba(220, 220, 220, 0.1)',
                  transform: "scale(1.022)",
                  cursor: "pointer" // Adding cursor style to indicate it's clickable
                }}
              />


              <Text as='b' fontSize='24px' pt="1rem" pb="1rem ">{title}</Text>

            </HStack>

            {isLanguageClicked ? (

              <Box alignItems="center"
                justifyContent="center">
                <Button variant="outline" fontSize={{ base: '14px', sm: '15px', md: '16px' }} onClick={closeSettings}>
                  <Text textAlign="center">English</Text>
                </Button>
              </Box>

            ) : isAdvancedClicked ? (

              <>

                <Box pl="1rem" pr="1rem" rounded="md" width={{ base: "100%", sm: "440px", lg: "440px" }}>
                  {isDeleteProfileClicked ? (

                    {/*  
                 
                      <DeleteYZAccounts />
                   */}
                  ) : (
                  <>



                    <Button
                      width="100%"
                      color="black"


                      leftIcon={<SlSupport fontSize="24px" />}
                      onClick={() => goToHelp()}
                      bg="white"
                      rounded="none"
                      justifyContent="flex-start"
                      textAlign="left"
                      _hover={{
                        bg: 'rgba(220, 220, 220, 0.1)',
                        transform: "scale(1.022)",
                      }}
                    >
                      <Flex justifyContent="space-between" alignItems="center" width={{ base: "100%", sm: "440px", lg: "440px" }}>
                        <Text fontSize='sm'>Help</Text>
                        <MdOutlineKeyboardArrowRight />
                      </Flex>


                    </Button>


                    <Spacer />

                    <Flex justifyContent="space-between" alignItems="center" width={{ base: "100%", sm: "440px", lg: "440px" }}
                      pb={{ base: "6rem", sm: "2rem", md: "2rem", lg: "2rem" }}>

                      <Button
                        width="100%"
                        color="red"
                        position="fixed"
                        bottom="0"
                        onClick={() => goDeleteAccount()}
                        bg="white"
                        pb={{ base: "6rem", sm: "2rem", md: "2rem", lg: "2rem" }}
                        rounded="none" // Remove rounded corners
                        justifyContent="flex-start" // Align content to the start (left)
                        textAlign="left" _hover={{
                          transform: "scale(1.022)",
                        }}>
                        <Text fontSize='sm'>Delete Account</Text>


                      </Button>
                    </Flex>


                  </>
                         )}


                </Box>


              </>
            ) : (

              <>







                <Box pl="1rem" pr="1rem" rounded="md" width={{ base: "100%", sm: "440px", lg: "440px" }}>
                  <Button
                    width="100%"
                    color="black"
                    leftIcon={<IoPersonCircleOutline fontSize="24px" />}
                    onClick={() => goToEditProfile()}
                    bg="white"
                    rounded="none"
                    justifyContent="flex-start"
                    textAlign="left"
                    _hover={{
                      bg: 'rgba(220, 220, 220, 0.1)',
                      transform: "scale(1.022)",
                    }}
                  >
                    <Flex justifyContent="space-between" alignItems="center" width={{ base: "100%", sm: "440px", lg: "440px" }}>
                      <Text fontSize='sm'>Edit Profile</Text>
                      <MdOutlineKeyboardArrowRight />
                    </Flex>


                  </Button>




                  <Button
                    width="100%"
                    color="black"
                    leftIcon={<IoLanguageOutline fontSize="24px" />}
                    onClick={() => goToLanguage()}
                    bg="white"
                    rounded="none" // Remove rounded corners
                    justifyContent="flex-start" // Align content to the start (left)
                    textAlign="left" _hover={{
                      bg: 'rgba(220, 220, 220, 0.1)',
                      transform: "scale(1.022)",
                    }}>
                    <Flex justifyContent="space-between" alignItems="center" width={{ base: "100%", sm: "440px", lg: "440px" }}>
                      <Text fontSize='sm'>Language</Text>
                      <MdOutlineKeyboardArrowRight />
                    </Flex>
                  </Button>

{/*  
                  <Button
                    width="100%"
                    color="black"
                    leftIcon={<GiGearStickPattern fontSize="24px" />}
                    onClick={() => goToAdvanced()}
                    bg="white"
                    rounded="none" // Remove rounded corners
                    justifyContent="flex-start" // Align content to the start (left)
                    textAlign="left" _hover={{
                      bg: 'rgba(220, 220, 220, 0.1)',
                      transform: "scale(1.022)",
                    }}>
                    <Flex justifyContent="space-between" alignItems="center" width={{ base: "100%", sm: "440px", lg: "440px" }}>
                      <Text fontSize='sm'>Advanced</Text>
                      <MdOutlineKeyboardArrowRight />
                    </Flex>
                  </Button>

*/}
                  <Spacer />





                  <Button

                    width="100%"
                    color="black"

                    onClick={() => logOutUser()}
                    leftIcon={<TbLogout2 fontSize="22px" />}
                    bg="white"
                    rounded="none" // Remove rounded corners
                    justifyContent="flex-start" // Align content to the start (left)
                    textAlign="left" _hover={{
                      bg: 'rgba(220, 220, 220, 0.1)',
                      transform: "scale(1.022)",
                    }}>


                    <Flex justifyContent="space-between" alignItems="center" width={{ base: "100%", sm: "440px", lg: "440px" }}>
                      <Text fontSize='sm'>Log out</Text>
                      <MdOutlineKeyboardArrowRight />
                    </Flex>



                  </Button>


                  {!isLargeScreen && (
                    <Box
                      mb={isBaseScreen ? "4rem" : null}
                      position="fixed"
                      style={{ borderRadius: '20px' }}
                      bottom="0"
                      p={4} // Adjust padding as needed
                      bg="white"
                      borderTopLeftRadius="md" // Optional: Add rounded corners to the top left
                      boxShadow="sm" // Optional: Add shadow for depth
                    >



                    </Box>

                  )}



                </Box>


              </>

            )}

          </Flex>
        </VStack>


      </HStack>

    </Box>
  )
}

export default SettingsSideBar