import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import { Box, Text, Flex, Image, HStack, Spacer, Button, useBreakpointValue, Tooltip } from '@chakra-ui/react'

import { useData } from '../App';
import { getAuth } from 'firebase/auth';

import { IoMdNotificationsOutline, IoMdNotifications } from "react-icons/io";

import { getNumberOfNotifications } from '../Navigators/NotificationsData';


const NavigationTop = () => {

    const auth = getAuth();
    const user = auth.currentUser;

    const [numberOfNotifications, setNumberOfNotifications] = useState(0);

    const [showPrayerRoomsButton, setShowPrayerRoomsButton] = useState(false);
    
    useEffect(() => {
        const fetchNotifications = async () => {
            if (user) {
                const notifications = await getNumberOfNotifications(user.uid);
                setNumberOfNotifications(notifications);
            }
        };

        fetchNotifications();
    }, [user]);


    const isSmallScreen = useBreakpointValue({ base: true, md: false });


    const { 
    
        isHomeSelected, setHomeSelected,
        setStoryJustPublished, setShowPublisherSettings,

        setNotificationsSelected, isNotificationsSelected,
        setAddNewPostSelected,
        setProfileSelected,
        setSettingsSelected,
        isPrayerRoomSelected, setPrayerRoomSelected,
        profileURL,

       
    
    } = useData();

    const navigate = useNavigate();

    const resetAudioPosting = () => {
        setStoryJustPublished(false);
        setShowPublisherSettings(false);

        setPrayerRoomSelected(false);
    }


    const goToPrayerRoom = () => {
      

        setHomeSelected(false);
        setPrayerRoomSelected(true);
        setNotificationsSelected(false);
        setAddNewPostSelected(false);
        setProfileSelected(false);

        setSettingsSelected(false);


        navigate("/prayerrooms");

    };

    const goToNotifications = () => {



        setHomeSelected(false);
        setPrayerRoomSelected(false);
        setNotificationsSelected(prevState => !prevState);
        setAddNewPostSelected(false);
        setProfileSelected(false);

        setSettingsSelected(false);



    };


    const goToHome = () => {
        resetAudioPosting();

        navigate('/requests');
        setHomeSelected(true);

        setPrayerRoomSelected(false);
        setNotificationsSelected(false);
        setAddNewPostSelected(false);
        setProfileSelected(false);

      

    };

    const goToAboutUs = () => {
        resetAudioPosting();

        navigate('/about');


    };

    const goToContactUs = () => {

        resetAudioPosting();
        navigate('/contact-us');


    };

    const goHome = () => {
        resetAudioPosting();

        navigate('/');
        setHomeSelected(false);

    };

    const isBaseOrSm = useBreakpointValue({ base: true, sm: true, md: false, lg: false, xl: false });

    const includedHomePagePath = '/';  // Only include the home page path
  
    const location = useLocation(); // Get the current location (pathname)
    
    useEffect(() => {
        const path = location.pathname; // Get the current pathname
        
        // Show the button only if we are on the home page ('/')
        if (path === includedHomePagePath || (!profileURL && path.includes(profileURL) ||  path.includes("prayerrooms"))) {
            setShowPrayerRoomsButton(false);
        } else {
            setShowPrayerRoomsButton(true);
        }
    }, [location.pathname]); 

    return (
        <Box
            position="fixed"
            top="0"
            left="0"
            width="100%"
            bg="white"
            borderBottom="0.2px solid #e4e4e4"
            transition="transform 0.3s ease"
            // transform={isOpen ? 'translateY(0)' : 'translateY(100%)'}
            zIndex="1"
            pt="5px"
            pb="5px"
        >




            <HStack
                // Responsive padding left
                pr={['12px', '12px', '12px', '3rem']} // Responsive padding right
            >
                <Flex flexDirection="row" alignItems="center">



                    <Image
                        src="/PrayerSendLogoText.png"
                        alt="PrayerSend App Logo"
                        maxH="70px"
                        onClick={goHome}
                    />


                </Flex>

                <Spacer />

                <HStack >


                    <Flex gap={5}>




                        {user && isSmallScreen && (


                            <Tooltip
                                hasArrow
                                label={"Notifications"}
                                placement='right'
                                ml={1}
                                openDelay={500}
                                display={{ base: "block", md: "none" }}

                            >
                                <Flex
                                    gap={4}
                                    borderRadius={6}
                                    p={2}
                                    w={{ base: 10, md: "full" }}
                                    alignItems="center"
                                    justifyContent="center"
                                    onClick={goToNotifications}
                                    _hover={{
                                        bg: 'gray.100',
                                        transform: "scale(1.022)",
                                    }} style={{
                                        transition: "transform 0.1s",
                                        position: 'relative'  // Ensure this container is relative
                                    }}>
                                



                                        {isNotificationsSelected ? <IoMdNotifications style={{ fontSize: "24px", fontWeight: "bold" }} /> : <IoMdNotificationsOutline style={{ fontSize: "24px" }} />}

                                        {numberOfNotifications !== null && numberOfNotifications > 0 && (

                                        <Box
                                            bg="red"
                                            borderRadius="50%"

                                            display="inline-flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            width="1.3rem"
                                            height="1.2rem"
                                            position="absolute"
                                            top="4px"
                                            left="18px"




                                        >
                                            <Text color="white" as='b' fontSize="11px">{numberOfNotifications}</Text>
                                        </Box>
)}
                                   




                                </Flex>
                            </Tooltip>
                        )}


{showPrayerRoomsButton ? (
     

          <Button
              width="full"
              onClick={goToPrayerRoom}
              size='sm'
              variant='ghost'
              fontSize="16px"
              fontWeight="normal"
              mr={!isBaseOrSm ? "2rem" : null}
              as='b'
              _hover={{
                  color: '#07BEFF', // White text color
                  borderColor: 'white', // White border color
              }}
          >
              PrayerRooms
          </Button>
          
         

) : (

<>
             <Button
                            width="full"
                            onClick={goToHome}
                            size='sm'
                            variant='ghost'
                            fontSize="16px"
                            fontWeight="normal"
                            mr={!isBaseOrSm ? "2rem" : null}
                            as='b'
                            _hover={{
                                color: '#07BEFF', // White text color
                                borderColor: 'white', // White border color
                            }}
                        >
                            Prayer Requests
                        </Button>
                      </>

)}



                        {!isBaseOrSm && (
                            <>

                                <Button
                                    width="full"
                                    onClick={goToAboutUs}
                                    size='sm'
                                    variant='ghost'
                                    fontSize="16px"
                                    fontWeight="normal"
                                    as='b'
                                    _hover={{
                                        color: '#07BEFF', // White text color
                                        borderColor: 'white', // White border color
                                    }}
                                >
                                    About
                                </Button>


                                <Button
                                    width="full"
                                    onClick={goToContactUs}
                                    size='sm'
                                    variant='ghost'
                                    fontSize="16px"
                                    fontWeight="normal"
                                    as='b'
                                    _hover={{
                                        color: '#07BEFF', // White text color
                                        borderColor: 'white', // White border color
                                    }}
                                >
                                    Contact
                                </Button>

                            </>
                        )}

                    </Flex>





                </HStack>

            </HStack>


        </Box>

    )
}

export default NavigationTop