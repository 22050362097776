import React, { useRef, useEffect } from 'react';
import { VStack, HStack, Image, Spacer, Text, Button, Flex, Box } from "@chakra-ui/react";
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { useData } from '../App';

const SlideUpLoginSignUp = () => {

    const { setShowLoginPopup, setShowFirstStepSignUp, setIsLogin, setHomeSelected } = useData();
 
    const navigate = useNavigate();

    const goToRequestPrayer = () => {


        navigate('/');
        setHomeSelected(false);

    };

    const openLogin = () => {


        setShowFirstStepSignUp(true);
        setIsLogin(true);
        setShowLoginPopup(true);


    };



    return (
        <Box
            position="fixed"
            bottom="0"
            left="0"
            width="100%"
            bg="rgba(0, 0, 0, 0.88)" // Dark transparent background
            color="white" // White text color
            borderTop="1px solid #e4e4e4"
            transition="transform 0.3s ease"
            // transform={isOpen ? 'translateY(0)' : 'translateY(100%)'}
            zIndex="1"
            pt="1rem"
            pb="1rem"
        >
             <Helmet>
                <title>{`PrayerSend · Send your prayers to God`}</title>
                <meta name="description" content="PrayerSend - Send your prayers to God." />
                <meta charset="UTF-8" />
            </Helmet>

            <HStack
                pl={['1rem', '6rem', '9rem']} // Responsive padding left
                pr={['1rem', '6rem', '9rem']} // Responsive padding right
            >
                <Flex flexDirection="row" alignItems="center">



                    <Image
                         src="/PrayerSendLogo.png"
                        alt="PrayerSend Logo"
                        className="post-image"
                        style={{ width: "50px", height: "50px" }}
                      
                    />
                   
                    <VStack alignItems="flex-start" ml={5} color="white"> {/* Set text color to white */}
                        <Text as='b' fontSize="md">Do you have a prayer request?</Text>
                        <Text fontSize="md">Share your prayer request and get people from around the world to pray for you</Text>
                    </VStack>
                </Flex>

                <Spacer />

                <VStack >



                    <Button
                        width="full"
                        onClick={goToRequestPrayer}

                        size='sm'
                        bg="#64a4d3"
                        color="white"
                        px={{ base: '1rem', sm: '1.5rem', md: '2rem' }}
                        _hover={{
                            bg: 'white',
                            color: '#64a4d3',
                        }}
                    >
                        Share a Prayer Request
                    </Button>



                    <Button
                        variant="outline"
                        width="full"
                        size='sm'
                        onClick={openLogin}
                        bg="transparent"
                        color="white"
                        px={{ base: '1rem', sm: '1.5rem', md: '2rem' }}
                        _hover={{
                            bg: 'white',
                            color: '#64a4d3',
                            outline: 'none', // Remove the outline on hover
                        }}

                    >
                        Log in
                    </Button>



                </VStack>

            </HStack>

        </Box>
    )
}

export default SlideUpLoginSignUp