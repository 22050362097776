import React, { useState, useEffect, useRef } from 'react';
import { Center, useToast } from '@chakra-ui/react';
import { VStack, HStack, Text, Box, Spacer, Image, Button, Flex, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, useBreakpointValue } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import PostAudio from './PostAudio';
import PrayerAudio from './PrayerAudio';
import { FaRegHeart } from "react-icons/fa";
import PrayerRequests from '../Home/PrayerRequests';

import { IoArrowBack } from "react-icons/io5";

import PostPrayersList from './PostPrayersList';

import { checkForPrayers } from './PrayersUpload';


import { currentUserId } from '../Utilities/firebase';

import PrayingHandsGlow from '../PrayingHandsGlow';

import { AiOutlineLink } from 'react-icons/ai';

import ShareOptions from '../ShareOptions';
import SlideUpPanel from '../Footers/SlideUpPanel';

import { unblockUser, checkBlockUser } from '../Popups/BlockUser';

import { getAuth } from 'firebase/auth';

import { Helmet } from 'react-helmet';


import LoadingPage from '../LoadingPage';

import PrayerRequestNotFound from '../PrayerRequestNotFound';


import { PiHandsPrayingFill } from "react-icons/pi";


// <FaRegHeart />
import { FcLike } from "react-icons/fc";
// <FcLike />
import { PiShareFat } from "react-icons/pi";
// <PiShareFat />
import { FaRegComment } from "react-icons/fa";
// <FaRegComment />
import { RiSendPlaneFill } from "react-icons/ri";
// <RiSendPlaneFill />
import { HiDotsHorizontal } from "react-icons/hi";
// <HiDotsHorizontal />
import { MdOutlineReportGmailerrorred } from "react-icons/md";
// <MdOutlineReportGmailerrorred />
import { MdBlock } from "react-icons/md";
// <MdBlock />

import { CgUnblock } from "react-icons/cg";


import { RiDeleteBin6Line } from "react-icons/ri";
// <RiDeleteBin6Line />



import { useData } from '../App';

// From PostDataModel 

import PostDataModel, { getNumberOfPrayers } from './PostDataModel';

// From Stories



import { checkIfPrayerRqExists } from './PostDataModel';



const PostPage = () => {
  const auth = getAuth();

  const user = auth.currentUser;

  const location = useLocation();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  //  const { isLoading, setIsLoading } = useData();

  const [urlExists, setURLExists] = useState(null); // State to track if the user exists


  const isSmallScreen = useBreakpointValue({ base: true, md: false });
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showAppQRCode, setShowAppQRCode] = useState(false);
  const [isBoxVisible, setIsBoxVisible] = useState(false);




  const [isUserBlocked, setUserBlocked] = useState(false);

  const toast = useToast();

  const didMountRef = useRef(false);

  // DOWNLOAD YENZEK APP
  const handleOpenGetYenZekApp = () => {
    setShowModal(false);
    setShowAppQRCode(true);

  };

  const handleCloseDownloadYenZekApp = () => {
    setShowAppQRCode(false);

  };

  // SCROLL HANDLING

  const handleScroll = () => {
    setScrollPosition(window.scrollY);




  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollPosition]); // Add scrollPosition as a dependency to useEffect

  const {
    name,
    userProfilePhoto,
    username,
    postId,
    postAudio,
    postAudioDuration,
    postAccess,
    postCaption,
    prayers,
    postTimes_Listened,
    postDate,
    uid,
    url,
  } = PostDataModel();

  document.title = (postCaption + " · PrayerSend");


// PRayer Data



const [numberOfPrayers, setNumberOfPrayers] = useState(0);



useEffect(() => {
  const fetchNumberOfPrayers = async () => {
    const prayersCount = await getNumberOfPrayers(url);
    setNumberOfPrayers(prayersCount);
  };

  fetchNumberOfPrayers();
}, [url]);




  // SHARING POST ON SOCIAL MEDIA 
  const shareLink = (platform) => {
    // Logic to handle sharing based on the platform clicked
    // console.log("Sharing on", platform);
    // Add your sharing logic here
  };

  const handleShareButtonClick = () => {
    setShowAppQRCode(false);
    setShowModal(true);

    if (isSmallScreen) {
      // Scroll to the top of the page
      window.scrollTo({ top: 0, behavior: "smooth" });
    }

  };

  const handleCloseModal = () => {
    setShowModal(false);

  };




  // NEW WAY OF GETTING PROFILE PAGE URL SET UP 






  const { profileURL, getUserProfilePageURL,
    setOpenReportFromPost,
    setItemSelectedToReport,
    setOpenBlockPopup,
    setPersonSelectedToBlock,
    setOpenDeletePrayerRequestPopup,
    setPrayerRequestToDelete,

    setShowLoginPopup,

    currentURL, setPageIsLoading, setNotFoundPageVisible,


    openPrayerSenderPopup, setOpenPrayerSenderPopup,
    personSelectedToPrayerSend, setPersonSelectedToPrayerSend,

    PrayerSendDomainName,

    
  } = useData();

  // getUserProfilePageURL(username); // NEW WAY To Update pageURL in App.jsx component





  const openPrayerRequestOptions = () => {
    setIsBoxVisible(false);

    setOpenDeletePrayerRequestPopup(true);


    setPrayerRequestToDelete({
      postId: postId,
      url: url,
      postCaption: postCaption,
      name: name,
      userProfilePhoto: userProfilePhoto,
      username: username,
      postAudio: postAudio,
      postAudioDuration: postAudioDuration,
      prayers: prayers,
      postTimes_Listened: postTimes_Listened,
      postDate: postDate
    });


  };



  const goToStories = () => {
    // Navigate to Profile
    navigate('/stories');
  };



  const openReport = () => {
    if (user) {
      setOpenReportFromPost(true)
      setItemSelectedToReport(url);

    } else {
      setShowLoginPopup(true);
    }

  }




  const goToUserProfile = () => {

    if (currentUserId !== uid) {
      //  console.log("CALLING DATA BECAUSE UID = USER_UID")
      // This allows us to see data on our user's profile page  as we go to it
      getUserProfilePageURL(username);// Update pageURL in App.jsx component

      // console.log('$$$$ WE HAVE USERNAME URL 2: ', username);

    } else {
      // console.log("NOT CALLING DATA BECAUSE YOU = UID")
    }




    navigate(`/${username}`, {

      state: {
        name: name,
        username: username,
        userProfilePhoto: userProfilePhoto,
        url: url,
        uid: uid
      }
    });






  }

  const [prayerExists, setPrayerExists] = useState(false);


  useEffect(() => {
    const fetchPrayers = async () => {
        const exists = await checkForPrayers(url);
        setPrayerExists(exists);

        console.log("checking this url", url)
      
    };

    fetchPrayers();
}, [url]);


  
  // LIKE STATUS

  /*
  const [didLikeStory, setDidLikeStory] = useState(false);
  let [currentPostId, setCurrentPostId] = useState([]);

  let [currentUserB, setCurrentUserB] = useState([]);

  const { hasLiked } = checkLikeStatus();

  const [numberOfLikes, setNumberOfLikes] = useState(0);


  useEffect(() => {
    const fetchLikes = async () => {
        const prayers = await getNumberOfPrayers(url);
        setNumberOfLikes(prayers);
    };

    fetchLikes();
}, [url]);

  /// console.log('OFFICIAL Number of likes: ' + numberOfLikes);
  /// console.log("3.OFFICIAL LIKES  --> " + hasLiked + "  " + didLikeStory)

  const [likesCount, setLikesCount] = useState();
  let [likeStatusButtonWasClicked, setLikeStatusButtonWasClicked] = useState(false);
  let determinedLikeStatus = hasLiked === didLikeStory;

  useEffect(() => {
      setLikesCount(numberOfLikes); // Update likes count in useEffect

      if (hasLiked) {
          setDidLikeStory(true);
      } else {
          setDidLikeStory(false);

      }
  }, [numberOfLikes]); // Dependency array ensures effect runs when numberOfLikes changes


  const handleLikeClick = () => {

      if (user) {
          if (hasLiked) {
              if (didLikeStory) {
                  setLikesCount(parseInt(numberOfLikes) - 1);
              } else {
                  setLikesCount(parseInt(numberOfLikes) + 1 - 1);
              }
          } else {
              if (didLikeStory) {
                  setLikesCount(parseInt(numberOfLikes) - 1 + 1);
              } else {
                  setLikesCount(parseInt(numberOfLikes) + 1);
              }
          }



          setLikeStatusButtonWasClicked(true);
          setDidLikeStory(!didLikeStory);
          setCurrentUserB(uid);
          setCurrentPostId(postId);

          //  console.log("^^^ 1.CHECK  --> " + hasLiked + "  " + didLikeStory)
          //  console.log("^^^ 1A.CHECK : DO WE UPDATE (LIKE STATUS)? --> " + determinedLikeStatus)


      } else {
          setShowLoginPopup(true);
      }
  };

*/


  /*
      useEffect(() => {
  
          const likeStatus = didLikeStory ? 'Like' : 'Unlike';
  
          try {
  
  
              if (likeStatusButtonWasClicked && !determinedLikeStatus) {
  
                  updateLikeStatusOnPost(likeStatus, currentUserB, currentPostId);
  
              }
          } catch (error) {
              console.error('Error in useEffect:', error);
  
          }
  
          setLikeStatusButtonWasClicked(false);
          setDidLikeStory(hasLiked);
          setLikesCount(numberOfLikes);
  
      }, [postId, hasLiked]);
  
  */
  // HANDLE FOLLOW/FOLLOWING
  /*
  
      let [followingStatusButtonWasClicked, setFollowingStatusButtonWasClicked] = useState(false);
  
      const [didFollow, setDidFollow] = useState(false);
  
  
  
      useEffect(() => {
          const fetchData = async () => {
  
              if (!followingStatusButtonWasClicked && currentUserId !== uid) {
                  const result = await checkFollowingStatus(uid);
                  setDidFollow(result);
              }
          };
  
          fetchData();
      }, [uid, followingStatusButtonWasClicked]); // Includ
      //console.log("2.ATEST:: Am I FOLLOWING?", didFollow)
  
  
  
      const handleNewFollowClick = () => {
          if (user) {
              setFollowingStatusButtonWasClicked(true);
              setDidFollow(prevDidFollow => !prevDidFollow);
  
  
              if (!didFollow) {
                  addAsFollowing(uid);
  
              } else {
                  setAsUnfollow(uid);
  
              }
  
  
          } else {
              setShowLoginPopup(true);
          }
  
      };
  
  */


  const isScrollPositionGreaterThan100 = scrollPosition > 100;





  const openBlockUserPopup = () => {
    setPersonSelectedToBlock({ uid: uid, name: name, username: username, profilePhoto: userProfilePhoto });

    setOpenBlockPopup(true)


  }


  const openPrayerSender = () => {

    if (user) {
    setPersonSelectedToPrayerSend({ uid: uid, name: name, username: username, profilePhoto: userProfilePhoto, postCaption: postCaption, postURL: url });

    setOpenPrayerSenderPopup(true)

    } else {
      setShowLoginPopup(true);
    }
 

  }

  const initializedUnblockUser = () => {

    setUserBlocked(false)

    unblockUser(uid);


  }


  useEffect(() => {
    const fetchData = async () => {
      if (uid && currentUserId !== uid) {
        const result = await checkBlockUser(uid);
        setUserBlocked(result);
      }
    };

    fetchData();
  }, [currentUserId, uid]);

  const copyLinkToClipboard = () => {
    setIsBoxVisible(false);
    navigator.clipboard.writeText(`${PrayerSendDomainName}listen=${url}`)

      .then(() => {
        toast({
          title: "Link copied",
          status: "success",
          duration: 3000, // Optional duration in milliseconds
          isClosable: true,
        });

      })
      .catch(error => {
        console.error('Failed to copy link: ', error);

      });
  };


  useEffect(() => {
    setIsLoading(true);

    const checkStoryExistance = async () => {
      if (postCaption) {
        setURLExists(true);
        setIsLoading(false);
        setNotFoundPageVisible(false);
      } else {
        try {
          //  console.log("1.Htest Does Story exist?" , currentURL)

          const exists = await checkIfPrayerRqExists(currentURL); // Call getUserByUsername function
          setURLExists(exists); // Update userExists state based on function result
          if (exists) {
            setNotFoundPageVisible(false);
          }

        } catch (error) {
          console.error('Error checking if user exists:', error);
          setURLExists(false); // Set userExists to false in case of an error
        } finally {
          setIsLoading(false); // Set loading to false when useEffect finishes
          setPageIsLoading(false);

        }

      }
    };

    if (currentURL) {
      checkStoryExistance(); // Call the function to check if user exists


    }
  }, [currentURL]);

  const goBackToPrayerRq = () => {
      navigate("/requests");
  };


  if (isLoading) {
    return (

      <LoadingPage />

    );
  }

  if (urlExists) {


    return (

      

      <VStack spacing={4} width="100%" pb="4rem" pt={user ?"6rem" : "2rem"}>

{isSmallScreen && (
  <HStack  justifyContent="flex-start" width="100%" alignItems="center" pl="1rem">
    <IoArrowBack onClick={() => goBackToPrayerRq()} fontSize='24px' style={{ alignSelf: 'center' }} />
    <Text onClick={() => goBackToPrayerRq()}  fontSize='20px' style={{ alignSelf: 'center' , fontWeight: "500"}}>Prayer Requests</Text>
  </HStack>
)}
        <Helmet>
          <title>{`${postCaption} - PrayerSend`}</title>
          <meta name="description" content={postCaption} />
          <meta charSet="UTF-8" />

          {/* Open Graph Meta Tags */}
          <meta property="og:title" content={postCaption} />
          <meta property="og:description" content={`${postCaption} - PrayerSend`} />
          <meta property="og:image" content={userProfilePhoto} />
          <meta property="og:url" content={`${PrayerSendDomainName}listen=${url}`} />
          <meta property="og:type" content="article" />

          {/* Twitter Card Meta Tags */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={postCaption} />
          <meta name="twitter:description" content={`Listen to more prayer requests by ${name} (@${username}) on PrayerSend`} />
          <meta name="twitter:image" content={userProfilePhoto} />
        </Helmet>



        <Flex direction={{ base: "column", sm: "column", md: "row" }} gap={(isSmallScreen ? null : 5)}>



          <VStack
            pt={(scrollPosition > 100 && !isSmallScreen) ? 10 : (scrollPosition > 100 ? 0 : 10)}
            position={(scrollPosition > 100 && !isSmallScreen) ? 'fixed' : 'static'}
            bg={(scrollPosition > 100) ? "white" : "transparent"}



          >

            {/* Audio and caption */}



            {/* Profile picture (centered) */}
            {/* Render large profile picture only if scroll position is less than 300px */}
           


            <Box alignItems="center" bg="white" maxWidth={500} position={(scrollPosition > 100 && isSmallScreen) ? 'fixed' : 'static'} width={(scrollPosition > 100 && isSmallScreen) ? '100vw' : 'auto'} pl={{ base: '1rem', sm: '4rem', md: '1rem' }} pr={{ base: '1rem', sm: '1rem', md: '0rem' }}>
              {/* Audio and caption */}
              {/* Audio and caption */}
              <Flex flexDirection="column" alignItems="flex-start">
                {/* Audio component */}

                <PostAudio
                  postAudio={postAudio}
                  postAudioDuration={postAudioDuration}
                  postTimes_Listened={postTimes_Listened}
                  postId={postId}
                  storyURL={url}
                  creatorUID={uid}
                />

                {/* Caption */}
                <Text fontSize="18" fontWeight="bold">{postCaption}</Text>



                <Flex pt="2" alignItems="flex-start">
                  <Flex alignItems="flex-start">
                    <Text fontSize="12" color="gray.500">{postTimes_Listened}</Text>
                    <Text pl="1" fontSize="12" color="gray.500">👂</Text>
                    <Text pl="2" fontSize="12" color="gray.500">·</Text>
                    <Text pl="2" fontSize="12" color="gray.500">{postDate}</Text>


                  </Flex>
                
                  <Flex pl="20" alignItems="flex-end" justifyContent="center">


                    <Flex flexDirection="row">


              

                      <Flex pl="3">
                      {currentUserId !== uid ? (
                        <Button
                          size="xs"
                          colorScheme="gray"
                          variant="solid"
                          rounded="full"
                          onClick={openPrayerSender}
                          _hover={{
                            color: "white",
                            bg: '#FFCC00',
                            transform: "scale(1.022)",
                          }} style={{
                            transition: "transform 0.5s",
                          }}

                        >
                          <Flex alignItems="center" gap="2px">
                             <PiHandsPrayingFill size={14} />
                              Send a prayer
                           
                          </Flex>
                        </Button>
                      ) : (
                       <Spacer pl="5rem"/>
                      )}
                      </Flex>
              

                      <Flex pl="3">
                        <Button onClick={handleShareButtonClick} size="xs" colorScheme="gray" variant="solid" rounded="full">
                          <Flex alignItems="center">
                            <PiShareFat size={14} />
                            <Text pl="1" fontSize="12" color="black">Share</Text>
                          </Flex>
                        </Button>
                      </Flex>



                      <Flex
                        pl="3"
                        position="relative"
                        onMouseEnter={() => {
                          if (!isSmallScreen) {
                            setIsBoxVisible(true);
                          }
                        }} // Set visibility to true when hovered, but only if not on small screen
                        onMouseLeave={() => {
                          if (!isSmallScreen) {
                            setIsBoxVisible(false);
                          }
                        }} // Set visibility to false when not hovered, but only if not on small screen
                        onClick={() => {
                          if (isSmallScreen) {
                            setIsBoxVisible(true);
                          }
                        }} // Toggle visibility when clicked, but only if on small screen
                      >
                        <Button
                          size="xs"
                          colorScheme="gray"
                          variant="solid"
                          rounded="full"
                          mb={isBoxVisible ? "10px" : 0}
                        >
                          <Flex alignItems="center">
                            <HiDotsHorizontal size={14} />
                          </Flex>
                        </Button>
                        {/* Panel to appear on hover for large screens and on click for small screens */}
                        {(isBoxVisible) && (
                          <Box
                            position="absolute"
                            top="100%"
                            left="50%"
                            transform="translateX(-50%)"
                            bg="white"
                            borderRadius="5%" // Rounded corners with 5% radius
                            boxShadow="dark-lg" // Dark shadow effect
                            p="2"
                            opacity={isBoxVisible ? 1 : 0} // Set opacity based on isBoxVisible state
                            pointerEvents={isBoxVisible ? "auto" : "none"} // Enable pointer events only when box is visible
                            transition="opacity 0.2s"
                            onMouseEnter={() => setIsBoxVisible(true)} // Set visibility to true when pointer enters box
                            onMouseLeave={() => setIsBoxVisible(false)} // Set visibility to false when pointer leaves box
                          >


                            {currentUserId !== uid ? (
                              <>
                                {/* Content inside the box */}
                                <HStack spacing={2} className="share-option" pr={3} pl={3}>
                                  <MdOutlineReportGmailerrorred size={18} mr={2} />
                                  <Text as='b' fontSize='xs' colorScheme="black" mb="1"
                                    onClick={openReport}>
                                    Report
                                  </Text>
                                </HStack>
                                {/* Separator */}

                                {user && (<Box justifyContent="center" width="90%" h="0.5px" bg="gray.300" mb="1" margin="auto" />)}
                                {/* Block */}
                                {isUserBlocked ? (
                                  <>
                                    <HStack spacing={2} className="share-option" pr={3} pl={3}>
                                      <CgUnblock size={18} mr={2} /> {/* Add margin to the right */}
                                      <Text as='b' fontSize='xs' colorScheme="black"
                                        onClick={initializedUnblockUser}>
                                        Unblock
                                      </Text>
                                    </HStack>
                                  </>
                                ) : (
                                  <>
                                    {user && (
                                      <HStack spacing={2} className="share-option" pr={3} pl={3}>
                                        <MdBlock size={18} mr={2} /> {/* Add margin to the right */}
                                        <Text as='b' fontSize='xs' colorScheme="black"
                                          onClick={openBlockUserPopup}>
                                          Block
                                        </Text>
                                      </HStack>

                                    )}

                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                {/* Block */}

                                <HStack width="40" spacing={2} className="share-option" pr={3} pl={3}
                                  onClick={openPrayerRequestOptions}>
                                  <RiDeleteBin6Line size={18} mr={2} /> {/* Add margin to the right */}
                                  <Text as='b' fontSize='xs' colorScheme="red" >

                                    Delete
                                  </Text>
                                </HStack>

                                <Box justifyContent="center" width="90%" h="0.5px" bg="gray.300" mb="1" margin="auto" />
                                {/* Copy Link */}

                                <HStack width="40" spacing={2} className="share-option" pr={3} pl={3}>
                                  <AiOutlineLink size={18} mr={2} /> {/* Add margin to the right */}
                                  <Text as='b' fontSize='xs' colorScheme="black" onClick={() => copyLinkToClipboard()}>
                                    Copy Link
                                  </Text>
                                </HStack>


                                <Box justifyContent="center" width="90%" h="0.5px" bg="gray.300" mb="1" margin="auto" />
                                {/* Share to... */}

                                <HStack width="40" spacing={2} className="share-option" pr={3} pl={3}>
                                  <PiShareFat size={18} mr={2} /> {/* Add margin to the right */}
                                  <Text as='b' fontSize='xs' colorScheme="black" onClick={() => setShowModal(true)}>

                                    Share to...
                                  </Text>
                                </HStack>



                              </>
                            )}
                          </Box>
                        )}
                      </Flex>




                    </Flex>
                  </Flex>

                </Flex>



                {/* Components to start from the left of the profile picture */}
                <Flex pt="2" alignItems="center" >
                  {/* Profile picture (small) */}

                  <Flex flexDirection="row" onClick={goToUserProfile}>
                    <Image
                      borderRadius="full"
                      boxSize="30px"
                      src={userProfilePhoto}
                      alt="profile pic"
                    />




                    {/* Name and username */}
                    <Flex flexDirection="column" ml={2}>
                      <Text fontSize="12">{name}</Text>
                      <Text fontSize="12">{username}</Text>
                    </Flex>
                  </Flex>

                  <Box pl={4}>

                    



                  </Box>


                </Flex>



              </Flex>

            


{prayerExists ? (

  <>
  {currentUserId !== uid ? (
  <Text mt="1rem" fontSize={10} textAlign="center">
        {numberOfPrayers} {numberOfPrayers === 1 ? 'person has' : 'people have'} prayed for {name}'s prayer request
      </Text>
  ) : (
    <>
 
    <Text mt="1rem" fontSize={10} textAlign="center">
    {numberOfPrayers} {numberOfPrayers === 1 ? 'person has' : 'people have'} prayed for your prayer request
  </Text>

</>
  )}

      <VStack
  mt="1rem"
>


<PostPrayersList postURL={url} postCreatorName={name}/>



</VStack>


</>

  ):(

    <>
       {currentUserId !== uid  ? (
     <Text mt="1rem" fontSize={10} textAlign={isSmallScreen? "center" : null} mx={isSmallScreen? "5rem" : null} >
        No one has prayed for {name}'s prayer request yet. Be the first and send a prayer for {name}!
      </Text>

) : (



    <>


<>
  <VStack>
  <Text mt="1rem" fontSize={10} textAlign={isSmallScreen? "center" : null} mx={isSmallScreen? "5rem" : null} >
  No one has prayed for your prayer request yet. Share your prayer request with a friend
</Text>

<Button
color="#64a4d3" colorScheme="black" variant="outline" size="sm" mr={2}
_hover={{
    color: "white",
    bg: '#64a4d3',
    transform: "scale(1.022)",
}} style={{
    transition: "transform 0.5s",
}}
p="2" // Add padding for better click area
onClick={handleShareButtonClick}
>
Share now

</Button>
</VStack>

</>



</>



)}
    </>
  )}

              {(scrollPosition > 100 && isSmallScreen) && (
                <Flex pt={5} justifyContent="center" width="100%">
                  <Box width={200} h="0.5px" bg="gray.300" mb="1" />
                </Flex>
              )}

            </Box>


          </VStack>



          {isSmallScreen && (
            <Flex pt={5} justifyContent="center" width="100%">
              <Box width={200} h="0.5px" bg="gray.300" mb="4" />
            </Flex>
          )}

{!isSmallScreen && (
          <Box
            pt={5}

            style={{ marginLeft: (scrollPosition > 100 && !isSmallScreen) ? "400px" : "auto" }} pl={{
              base: isScrollPositionGreaterThan100 ? '20px' : '0px',
              sm: isScrollPositionGreaterThan100 ? '60px' : '0px',
              md: isScrollPositionGreaterThan100 ? '60px' : '0px',
            }}
            pr={{
              base: isScrollPositionGreaterThan100 ? '40px' : '0px',
              sm: isScrollPositionGreaterThan100 ? '50px' : '0px',
              md: isScrollPositionGreaterThan100 ? '0px' : '0px',
            }}>
            <PrayerRequests />

            {/* SlideUpPanel */}
            {isSmallScreen && (
              <SlideUpPanel isOpen={showModal && scrollPosition <= 100} onClose={handleCloseModal} shareLink={`${PrayerSendDomainName}listen=${url}`} />

            )}



          </Box>
)}


        </Flex>

        {/* Modal */}
     
          <Modal isOpen={showModal} onClose={handleCloseModal} size={{ base: "sm", sm: "sm", md: "md" }}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader textAlign="center">Share to...</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <ShareOptions shareLink={`${PrayerSendDomainName}listen=${url}`} onCloseModal={handleCloseModal} />
              </ModalBody>
              <ModalFooter>
                {/* You can add additional footer actions if needed */}
              </ModalFooter>
            </ModalContent>
          </Modal>



   





      </VStack>

    );

  }

  if (!isLoading) {
    return <PrayerRequestNotFound />;

  }

};

export default PostPage;