import React, { useState , useEffect} from 'react'
import { useData } from '../App';
import { VStack, Text, HStack, Spacer, Box, Button, Flex, Image, CircularProgress, useBreakpointValue } from '@chakra-ui/react';
import { FaCirclePlay } from 'react-icons/fa6';
import { BsThreeDots } from 'react-icons/bs';

import { useNavigate } from 'react-router-dom';

import { deletePrayerRequest } from './PostDataModel';

import useUserUsername from '../pages/ProfilePage/ProfileUsername';

const DeletePrayerRequestPopup = ({ onCloseDeletePrayerRequestPopup }) => {

    const { username } = useUserUsername();
    const [isLoading, setIsLoading] = useState(false);
    const [wasStoryDeleted, setStoryDelete] = useState(false);

    const isSmallScreen = useBreakpointValue({ base: true, md: false });


    const { openDeletePrayerRequestPopup, setOpenDeletePrayerRequestPopup,
        prayerRequestToDelete, setprayerRequestToDelete, 
     } = useData();




    const navigate = useNavigate();



    const closePopup = async () => {

        onCloseDeletePrayerRequestPopup();

    }

    const initialiateDeletePrayerRequest = async () => {
        // SEND TO DATABASE


        setIsLoading(true);

        deletePrayerRequest(prayerRequestToDelete.url);
        await new Promise(resolve => setTimeout(resolve, 2400));


            setIsLoading(false);
            setStoryDelete(true);
            setTimeout(() => {
                navigate(`/${username}`);

                onCloseDeletePrayerRequestPopup();
            }, 1000);

      

    };






    return (
        <VStack>

            <Text as='b' pb="10px" fontSize={20}>Delete this prayer request?</Text>


            <VStack>
                <VStack>
                    <Flex
                        pl="20px"
                        alignItems="flex-start"
                        justifyContent="center"
                        _hover={{
                            bg: 'rgba(220, 220, 220, 0.1)', // Light gray with 10% opacity on hover
                            transform: "scale(1.022)",
                        }}
                        style={{
                            transition: "transform 0.1s",
                        }}>
                        {/* 1st STORY POSTS GO HERE  */}
                        <Flex pr="10" alignItems="flex-start" justifyContent="center"> {/* Align items to the top */}
                            <Box pt="4" mr="4"> {/* Margin right to create space between image and text */}
                                {/* 
         FULL IMAGE WAS HERE 
         */}
                                {!isSmallScreen && (
                                    <Image
                                        src={prayerRequestToDelete.userProfilePhoto}
                                        borderRadius='full'
                                        boxSize='50px'
                                        boxShadow="base"
                                        className="profile-image-circle"
                                    />
                                )}
                            </Box>

                            <Box width="100%">

                                <Box width={{ base: "250px", sm: "270px", md: "300px" }}

                                    minWidth="100px">

                                    <Flex direction="column" pt="0" maxWidth="300px"> {/* Set maximum width */}
                                        <Text fontSize={{ base: '14px', sm: '16px', md: '18px' }} fontWeight="bold">{prayerRequestToDelete.postCaption}</Text> {/* SubJect of Story */}
                                    </Flex>

                                    {isSmallScreen ? (
                                        <Flex direction="row" pt="5px">
                                            {/* 
         FULL IMAGE WAS HERE 
         */}
                                            <Image
                                                borderRadius="full"
                                                boxSize="30px"
                                                src={prayerRequestToDelete.userProfilePhoto}
                                                alt="profile pic"
                                            />

                                            <Flex flexDirection="column" ml={2}>
                                                <Text fontSize="12">{prayerRequestToDelete.name}</Text> {/* Replaced name with TCselectedName */}
                                                <Text fontSize="12">{prayerRequestToDelete.username}</Text> {/* Replaced username with TCselectedUsername */}
                                            </Flex>
                                        </Flex>
                                    ) : (

                                        <>
                                            <Text fontSize="12">{prayerRequestToDelete.name}</Text> {/* Replaced name with TCselectedName */}
                                            <Text fontSize="12">{prayerRequestToDelete.username}</Text> {/* Replaced username with TCselectedUsername */}
                                        </>


                                    )}

                                    <Flex pt="2" alignItems="flex-start"> {/* Align items to the top */}


                                        <Text fontSize="12" color="gray.500">{prayerRequestToDelete.postTimes_Listened}</Text>
                                        <Text pl="1" fontSize="12" color="gray.500">👂</Text>
                                        <Text pl="2" fontSize="12" color="gray.500">·</Text>
                                        <Text pl="2" fontSize="12" color="gray.500">{prayerRequestToDelete.postDate}</Text>



                                    </Flex>

                                    <Flex pt="2">


                                        <Box
                                            width={{ base: '66%', md: '100%' }} // Adjust width based on screen size
                                            maxWidth="400px" // Maximum width
                                            minWidth="auto" // Minimum width set to auto
                                            height="0.5px" // Set the height explicitly
                                            backgroundColor="gray.300" // Set the background color
                                            marginBottom="4px" // Set the bottom margin
                                            mx="auto" // Center horizontally
                                        /> {/* Gray line */}

                                    </Flex>

                                </Box>

                            </Box>

                            {/* PLAY BOX OF STORY  */}

                            <Flex pt="3" flexDirection="column" alignItems="flex-end" justifyContent="center" ml="auto"> {/* Align to right */}
                                <Box justifyContent="center" alignItems="center">
                                    <Box>
                                        <Text fontSize="12" fontWeight="bold">{prayerRequestToDelete.postAudioDuration}</Text> {/* Replaced story.postAudioDuration with TCselectedPostAudioDuration */}
                                    </Box>
                                    <Spacer />
                                    <Box pt="2">
                                        <FaCirclePlay /> {/* Adjust icon size */}
                                    </Box>
                                    <Spacer />
                                    <Box pt="2">
                                        <BsThreeDots /> {/* Adjust icon size */}
                                    </Box>
                                </Box>
                            </Flex>


                        </Flex>
                    </Flex>




                </VStack>
            </VStack>












            {isLoading && (
                <>
                    <CircularProgress color='#081b4f' size="20px" isIndeterminate />
                    <Text fontSize='sm'>Deleting story...</Text>
                </>

            )}

            {wasStoryDeleted && (

                <Text m={3} as='b' fontSize='sm'>Deleted!</Text>


            )}

{!isLoading && !wasStoryDeleted && (
            <Flex flexDirection="row" justifyContent="space-between" gap={10}>

 
                <Button size='md' variant='outline' onClick={closePopup}>Cancel</Button>

       

                <Button size='md'
                    variant='outline'
                    color='gray'
                    onClick={() => initialiateDeletePrayerRequest()} // 

                    border="2px"
                    pl={{ base: "2rem", md: "2rem" }}
                    pr={{ base: "2rem", md: "2rem" }}
                    bg='white'
                    _hover={{
                        bg: 'red',
                        color: 'white',
                    }}>Delete</Button>

              

            </Flex>

)}


        </VStack>



    )
}


export default DeletePrayerRequestPopup